<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="1차심사" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- 1차심사 보고서 -->
              <c-btn 
                v-show="editable && !disabledPrint"
                label="1차심사 보고서" 
                icon="print"
                @btnClicked="firstAuditPrint" />
              <!--저장-->
              <c-btn
                v-show="editable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="param.suggestion"
                mappingType="PUT"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
              <c-btn
                v-show="editable && !disabled"
                :url="completeUrl"
                :isSubmit="isComplete"
                :param="param.suggestion"
                mappingType="PUT"
                label="완료"
                icon="check"
                @beforeAction="completeData"
                @btnCallback="completeCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                required
                :editable="editable"
                :disabled="disabled"
                type="edit"
                codeGroupCd="SAI_SUGGESTION_FIRST_AUDIT_CD"
                itemText="codeName"
                itemValue="code"
                name="suggestionFirstAuditCd"
                label="심사"
                v-model="param.suggestion.suggestionFirstAuditCd"
              >
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-dept
                :required="true" 
                :editable="editable"
                :disabled="disabled"
                label="개선실행부서"
                name="execDeptCd"
                v-model="param.suggestion.execDeptCd">
              </c-dept>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="포상금"
                suffix="원"
                type="number"
                name="bountyFirst"
                v-model="param.suggestion.bountyFirst">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="16"
                label="심사의견"
                name="suggestionFirstAuditOpinion"
                v-model="param.suggestion.suggestionFirstAuditOpinion">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'suggestionFirstAudit',
  props: {
    param: {
      type: Object,
      default: () => ({
        saiSugSuggestionId: '',
        suggestion: {
          saiSugSuggestionId: '',
          suggestionStepCd: '',
          suggestionNo: '',
          plantCd: '',
          regDeptCd: '',
          execDeptCd: '',
          suggestionDate: '',
          suggestionStatus: '',
          suggestionTitle: '',
          suggestionProblem: '',
          suggestDivisionTypeCd: null,
          suggestCategoryTypeCd: null,
          suggestionSuggest: '',
          suggestionBenefit: '',
          suggestionImprove: '',
          suggestionLocation: '',
          suggestionFirstAuditCd: '',
          suggestionSecondAuditCd: '',
          suggestionFirstAuditOpinion: '',
          suggestionSecondAuditOpinion: '',
          suggestionTypeEffect: '',
          suggestionIntangibleEffect: '',
          bountyFirst: '',
          bountySecond: '',
          sysApprovalRequestId: '',
          approvalStatusCd: '',
          vendorCd: '',
          approvalRequestFlag: '',
          regUserId: '',
          chgUserId: '',
        },
      }),
    },
  },
  data() {
    return {
      saveUrl: '',
      completeUrl: '',
      printUrl: '',
      editable: true,
      isSave: false,
      isComplete: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      return this.editable 
        && (this.$_.indexOf(this.$store.getters.auths, 'SAGI000021') === -1
        || this.param.suggestion.suggestionStepCd !== 'SSSSC00002')
    },
    disabledPrint() {
      return this.editable && this.$_.indexOf(this.$store.getters.auths, 'SAGI000021') === -1
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sai.sug.suggestion.update.url;
      this.completeUrl = transactionConfig.sai.sug.suggestion.complete.url;
      this.printUrl = selectConfig.sai.sug.suggestion.firstPrint.url;
      // code setting
      // list setting 
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',    // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?     // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.param.suggestion.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.saiSugSuggestionId = result.data
    },
    completeData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if(this.param.suggestion.suggestionFirstAuditCd !== 'SSFAC00002') {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '완료하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.param.suggestion.chgUserId = this.$store.getters.user.userId
                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',    // 확인
              message: '기각을 선택하면 바로 종료됩니다.<br/>저장하시겠습니까?', 
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.param.suggestion.chgUserId = this.$store.getters.user.userId;
                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } 
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      /**
       * 1. 기존에는 완료버튼을 누르면 스탭이 넘어감
       * 2. 기각 상태에서는 스탭이 넘어가는 대신 1차심사 상태에서 개선작성/2차심사가 사라져야함
       * 3. 개선작성은 사라지는데 2차심사가 사라지지 않는 현상이 있음
       * 4. 이에따라 조건을 다르게 주어 2차심사도 함께 없애려고함
       */
      
      /**
       * 1. 기각일 경우 this.param.saiSugSuggestionId을 이용하여 suggetionDetail에서 getDetail에서 suggestionStepCd를 이용하여 step을 지정
       * 
       * - step을 getDetail에서 suggestionStepCd를 이용하여 지정하는 것이 맞는지
       * - suggestionStepCd가 원하는 값으로 존재하는지
       * - saiSugSuggestionId를 이용하는게 맞는지
       */
      this.$emit('emitStep', {
        name: 'getDetail',
        param: { 
          saiSugSuggestionId: this.param.saiSugSuggestionId
        },
      })
    },
    firstAuditPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.param.saiSugSuggestionId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '1차심사 보고서_' + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>
